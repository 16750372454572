<template>
<div class="meo-user primary" :class="initialised ? 'initialised' : ''">
    <v-btn text color="dark" class="zindex-2 p-fixed top20 left5 pa-1" :to="{name : 'modules'}" v-if="$vuetify.breakpoint.xsOnly">
        <v-icon>mdi-chevron-left</v-icon>
        Back
    </v-btn>

    <div class="logo-row">
        <div class="circle"></div>
        <meo-ripple :config="config"></meo-ripple>
    </div>

    <div class="dialog-row">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-btn text color="dark" class="pa-1" :to="{name : 'modules'}" v-if="$vuetify.breakpoint.smAndUp">
                        <v-icon>mdi-chevron-left</v-icon>
                        Back
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>

        <transition name="slide-y-reverse-transition" mode="out-in" appear>
            <router-view />
        </transition>
    </div>

    <div class="footer-row pa-5">
        <p class="caption ma-0">&copy; 2021 Mastering Emotional Health</p>
    </div>
</div>
</template>

<script>
import MeoRipple from "@/components/misc/meo-ripple.vue";

export default {
    components: {
        MeoRipple
    },
    data() {
        return {
            initialised: false,
            config: {
                image: require("@/assets/logo.png"),
                padding: true
            }
        }
    },
    created() {
        this.initialised = window.sessionStorage.initialised || false

        // try {
        // 	let App = window.parent.Capacitor.Plugins.App
        // } catch (e) {
        // 	console.error("(App) Capacitor not found.")
        // 	this.initialised = true
        // }

        setTimeout(() => {
            this.initialised = true
            window.sessionStorage.initialised = true
        }, 3500)
    }
}
</script>

<style lang="scss">
.meo-user {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    overflow-x: hidden;
    overflow-y: scroll;

    .logo-row {
        position: relative;
        flex-grow: 1;
        flex-shrink: 0;

        .circle {
            background: #FFFFFF;
            width: 600px;
            height: 600px;
            border-radius: 100%;
            position: absolute;
            left: 50%;
            top: -50%;
            transform: translateX(-50%);
        }
    }

    @media(min-width: 600px) {
        .logo-row {
            .circle {
                width: 800px;
                height: 800px;
                top: -80%;
            }
        }
    }

    @media(min-width: 860px) {
        flex-direction: row;

        .logo-row {
            flex-grow: 1;
            flex-shrink: 0;
            width: 50%;
            display: flex;
            align-items: flex-start;

            .circle {
                left: 5%;
                top: -40%;
                width: 1400px;
                height: 1400px;
            }

            .meo-ripple {
                margin: 0;
            }
        }

        .dialog-row {
            flex-grow: 1;
            flex-shrink: 0;
            width: 50%;
            margin-top: 200px;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;

            .container {
                max-width: 500px;
                margin: 0 auto;
            }
        }

        .footer-row {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .dialog-row {
        z-index: 1;
        position: relative;
    }

    .footer-row {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 0;
        flex-shrink: 1;
        pointer-events: none;
    }

    &.initialised {
        .logo-row {
            min-height: 1px;
        }

        @media(max-width: 860px) {
            .footer-row {
                opacity: 0;
            }
        }
    }
}
</style>
